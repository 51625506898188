.border-header-top {
    background: white;
    position: relative;
    margin-top: -30px;
    height: 45px;
    border-radius: 16px;
}

.position-icon-card {
    position: relative;
    top: 2px;
    left: 2px;
    text-align: left;
}

.position-icon-card i {
    font-size: 24px; 
}

.Card {
    border-radius: 10px;
    background-color: #ec7000;
    color: #FFF;
    margin: auto;
    cursor: pointer;
    padding: 8px;
    height: 130px;
}

.Card p {
    margin: 25px 10px 0 3px;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.HomeCard {
    border: 2px solid #DDD;
    width: auto;
    margin: 5px 5px;
    border-radius: 10px;
}

.Context {
    text-align: right;
    margin: 0px 24px;
}

.Context .left {
    text-align: left;
}

.Context .right {
    text-align: right;
}

.saldo {
    margin-top: 5px;
    margin-bottom: 10px;
}

.Context div {
    justify-content: space-between !important;
}

.imgCard {
    margin: 23px auto;
    background-repeat: no-repeat;
    border-radius: 15px;
    width: 327px;
    height: 320px;
}

.imgCard1NaoPontua {
    background-image: url(../../Styles/img/Banner_1_NaoPontua.png);
}

.imgCard2NaoPontua {
    background-image: url(../../Styles/img/Banner_2_NaoPontua.png);
}

.imgCard3NaoPontua {
    background-image: url(../../Styles/img/Banner_3_NaoPontua.png);
}

.CardTitle {
    color: #FFF;
    text-align: start;
    padding: 20px;
}

.CardInfo {
    color: #FFF;
    border: 1px solid #FFF;
    text-align: center;
    padding: 2px;
    border-radius: 8px;
    width: 100px;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 12px;
}

.CardInfo.none {
    opacity: 0;
}

.info div.col-7 {
    padding: 20px;
    text-align: initial;
}

.info span {
    color: #FFF;
    font-size: 20px;
    text-align: left;
    line-height: 26px;
}

.button {
    color: #FFF;
    margin-top: 60px;
    
}

.btn-outline-primary {
    color: #FFF;
    border-color: #FFF;
}

h2 {
    font-size: 20px;
}

.focus {
   contain: size;
}

button {
    border: none;
}

.btn-card {
    padding: 0;
    width: 95%;
    background-color: #FFF;
    cursor: unset !important;
}
