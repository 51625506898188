@font-face {
    font-family: 'Itau Display Custom Bold';
    font-display: block;
    src: local('Itau Display Custom Bold'), url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay-Bold.eot');
    src: local('Itau Display Custom Bold'), url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal; 
}

@font-face {
    font-family: 'Itau Display Custom Regular';
    font-display: block;
    src: local('Itau Display Custom Regular'), url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay-Regular.eot');
    src: local('Itau Display Custom Regular'), url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Itau Display Custom XBd';
    font-display: block;
    src: local('Itau Display Custom XBd'), url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay_XBd.eot');
    src: local('Itau Display Custom XBd'), url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay_XBd.eot?#iefix') format('embedded-opentype'),
        url('https://s3.amazonaws.com/ltm.ragnarok.tenants.prod.40455/assets/fonts/ItauDisplay_XBd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} 