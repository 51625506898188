.carousel-indicators {
    bottom: 32px;
}

.carousel-indicators [data-bs-target] {
    border-radius: 50%;
    height: 6px;
    width: 6px;   
}

.carousel-control-next {
    right: 150px; 
}

.carousel-control-prev {
    left: 150px;
}

.carousel-control-next, .carousel-control-prev {
    top: initial;
    align-items: flex-end;
    margin-bottom: 15px;
    z-index: 999;
    bottom: 30px;
    width: 16px;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: transparent;
    border: 1px solid #fff;
}

.carousel-indicators .active {
    background-color: #fff !important;
}

.carousel-caption {
    left: 6%;
    text-align: left;
    top: 19%;
    width: 324px;
}

.carousel-caption-itau-shop {
    left: 8%;
    top: 41.5%;
}

.carousel-caption-credito-fatura {
    top: 31%; 
}

.carousel-caption-stix {
    top: 31%; 
}

.carousel-caption h5 {
    color: #FFF;
}

.carousel-caption p {
    color: #FFF;
}

.carousel-control-prev-icon {
    filter: invert(0) !important;
}

.carousel-control-next-icon {
    filter: invert(0) !important;
}

.cta-carrousel {
    border: 2px solid #fff;
    background-color: transparent;
    padding: 10px 20px;
    color: #fff;
    border-radius: 8px;
    display: inline-block;
    font-size: 16px;
}

.button{
    cursor: pointer;
   
}

.span-title {
    display: block;
}

.brc{
    color: #FFF;
}

button{
    border: none;
    background-color: rgba(255, 255, 255, 0);
}

.f-none:focus {
  outline-offset: 0.25em;
}

.img-carousel {
    width: 100%;
    min-height: 575px;
}