
@font-face {
  font-family: 'icomoon';
  src:  url('./icons/icomoon/fonts/icomoon.eot');
  src:  url('./icons/icomoon/fonts/icomoon.eot') format('embedded-opentype'),
    url('./icons/icomoon/fonts/icomoon.ttf') format('truetype'),
    url('./icons/icomoon/fonts/icomoon.woff') format('woff'),
    url('./icons/icomoon/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon_"], [class*=" icon_"] { 
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon.icon_add:before {
  content: "\e900";
  
}
.icon.icon_airplane:before {
  content: "\e901";
}
.icon.icon_alarm:before {
  content: "\e902";
  
}
.icon.icon_analyse:before {
  content: "\e903";
  
}
.icon.icon_armichair:before {
  content: "\e904";
  
}
.icon.icon_arrow_down:before {
  content: "\e905";
  
}
.icon.icon_arrow_left:before {
  content: "\e906";
  
}
.icon.icon_arrow_right:before {
  content: "\e907";
  
}
.icon.icon_arrow_up:before {
  content: "\e908";
  
}
.icon.icon_attach:before {
  content: "\e909";
  
}
.icon.icon_baby:before {
  content: "\e90a";
  
}
.icon.icon_bag:before {
  content: "\e90b";
  
}
.icon.icon_ball:before {
  content: "\e90c";
  
}
.icon.icon_ball_soccer:before {
  content: "\e90d";
  
}
.icon.icon_ballon:before {
  content: "\e90e";
  
}
.icon.icon_barcodes:before {
  content: "\e90f";
  
}
.icon.icon_basket:before {
  content: "\e910";
  
}
.icon.icon_beauty:before {
  content: "\e911";
  
}
.icon.icon_bike:before {
  content: "\e912";
  
}
.icon.icon_block:before {
  content: "\e913";
  
}
.icon.icon_bluetooth:before {
  content: "\e914";
  
}
.icon.icon_book:before {
  content: "\e915";
  
}
.icon.icon_bookmark:before {
  content: "\e916";
  
}
.icon.icon_browser:before {
  content: "\e917";
  
}
.icon.icon_bus:before {
  content: "\e918";
  
}
.icon.icon_calendar:before {
  content: "\e919";
  
}
.icon.icon_call:before {
  content: "\e91a";
  
}
.icon.icon_camera:before {
  content: "\e91b";
  
}
.icon.icon_car:before {
  content: "\e91c";
  
}
.icon.icon_card:before {
  content: "\e91d";
  
}
.icon.icon_cart:before {
  content: "\e91e";
  
}
.icon.icon_cash:before {
  content: "\e91f";
  
}
.icon.icon_chart:before {
  content: "\e920";
  
}
.icon.icon_chat:before {
  content: "\e921";
  
}
.icon.icon_check:before {
  content: "\e922";
  
}
.icon.icon_check_circle:before {
  content: "\e923";
  
}
.icon.icon_cinema:before {
  content: "\e924";
  
}
.icon.icon_clock:before {
  content: "\e925";
  
}
.icon.icon_close:before {
  content: "\e926";
  
}
.icon.icon_close_circle:before {
  content: "\e927";
  
}
.icon.icon_cloud:before {
  content: "\e928";
  
}
.icon.icon_cloud_computing:before {
  content: "\e929";
  
}
.icon.icon_cloud_down:before {
  content: "\e92a";
  
}
.icon.icon_cloud_up:before {
  content: "\e92b";
  
}
.icon.icon_colls:before {
  content: "\e92c";
  
}
.icon.icon_computer:before {
  content: "\e92d";
  
}
.icon.icon_conversation:before {
  content: "\e92e";
  
}
.icon.icon_convert:before {
  content: "\e92f";
  
}
.icon.icon_copy:before {
  content: "\e930";
  
}
.icon.icon_cruise:before {
  content: "\e931";
  
}
.icon.icon_dev:before {
  content: "\e932";
  
}
.icon.icon_dislike:before {
  content: "\e933";
  
}
.icon.icon_donation:before {
  content: "\e934";
  
}
.icon.icon_down:before {
  content: "\e935";
  
}
.icon.icon_download:before {
  content: "\e936";
  
}
.icon.icon_drink:before {
  content: "\e937";
  
}
.icon.icon_dryer:before {
  content: "\e938";
  
}
.icon.icon_dvd:before {
  content: "\e939";
  
}
.icon.icon_edit:before {
  content: "\e93a";
  
}
.icon.icon_equal:before {
  content: "\e93b";
  
}
.icon.icon_faceid:before {
  content: "\e93c";
  
}
.icon.icon_file:before {
  content: "\e93d";
  
}
.icon.icon_filter:before {
  content: "\e93e";
  
}
.icon.icon_fingerprint:before {
  content: "\e93f";
  
}
.icon.icon_folder:before {
  content: "\e940";
  
}
.icon.icon_folder_open:before {
  content: "\e941";
  
}
.icon.icon_fuel:before {
  content: "\e942";
  
}
.icon.icon_funnel:before {
  content: "\e943";
  
}
.icon.icon_game:before {
  content: "\e944";
  
}
.icon.icon_gift:before {
  content: "\e945";
  
}
.icon.icon_headphone:before {
  content: "\e946";
  
}
.icon.icon_helicopter:before {
  content: "\e947";
  
}
.icon.icon_help:before {
  content: "\e948";
  
}
.icon.icon_hierarch:before {
  content: "\e949";
  
}
.icon.icon_home:before {
  content: "\e94a";
  
}
.icon.icon_host:before {
  content: "\e94b";
  
}
.icon.icon_hotel:before {
  content: "\e94c";
  
}
.icon.icon_idea:before {
  content: "\e94d";
  
}
.icon.icon_joke:before {
  content: "\e94e";
  
}
.icon.icon_label:before {
  content: "\e94f";
  
}
.icon.icon_laptop:before {
  content: "\e950";
  
}
.icon.icon_left:before {
  content: "\e951";
  
}
.icon.icon_left_app:before {
  content: "\e952";
  
}
.icon.icon_like:before {
  content: "\e953";
  
}
.icon.icon_link:before {
  content: "\e954";
  
}
.icon.icon_list:before {
  content: "\e955";
  
}
.icon.icon_listing:before {
  content: "\e956";
  
}
.icon.icon_loading:before {
  content: "\e957";
  
}
.icon.icon_location:before {
  content: "\e958";
  
}
.icon.icon_lock:before {
  content: "\e959";
  
}
.icon.icon_login:before {
  content: "\e95a";
  
}
.icon.icon_logout:before {
  content: "\e95b";
  
}
.icon.icon_loupe:before {
  content: "\e95c";
  
}
.icon.icon_map:before {
  content: "\e95d";
  
}
.icon.icon_mechanic:before {
  content: "\e95e";
  
}
.icon.icon_medal:before {
  content: "\e95f";
  
}
.icon.icon_megaphone:before {
  content: "\e960";
  
}
.icon.icon_menu:before {
  content: "\e961";
  
}
.icon.icon_menu_app:before {
  content: "\e962";
  
}
.icon.icon_menu_app_close:before {
  content: "\e963";
  
}
.icon.icon_menu_close:before {
  content: "\e964";
  
}
.icon.icon_message:before {
  content: "\e965";
  
}
.icon.icon_message_read:before {
  content: "\e966";
  
}
.icon.icon_microwave:before {
  content: "\e967";
  
}
.icon.icon_more:before {
  content: "\e968";
  
}
.icon.icon_motorcycle:before {
  content: "\e969";
  
}
.icon.icon_movie:before {
  content: "\e96a";
  
}
.icon.icon_music:before {
  content: "\e96b";
  
}
.icon.icon_newsletter:before {
  content: "\e96c";
  
}
.icon.icon_no_view:before {
  content: "\e96d";
  
}
.icon.icon_notification:before {
  content: "\e96e";
  
}
.icon.icon_notification_active:before {
  content: "\e96f";
  
}
.icon.icon_options:before {
  content: "\e970";
  
}
.icon.icon_options_vertical:before {
  content: "\e971";
  
}
.icon.icon_order:before {
  content: "\e972";
  
}
.icon.icon_package:before {
  content: "\e973";
  
}
.icon.icon_pan:before {
  content: "\e974";
  
}
.icon.icon_password:before {
  content: "\e975";
  
}
.icon.icon_pay:before {
  content: "\e976";
  
}
.icon.icon_pen:before {
  content: "\e977";
  
}
.icon.icon_phone:before {
  content: "\e978";
  
}
.icon.icon_picture:before {
  content: "\e979";
  
}
.icon.icon_pin:before {
  content: "\e97a";
  
}
.icon.icon_placeholder:before {
  content: "\e97b";
  
}
.icon.icon_play:before {
  content: "\e97c";
  
}
.icon.icon_plug:before {
  content: "\e97d";
  
}
.icon.icon_points:before {
  content: "\e97e";  
}
.icon.icon_presentation:before {
  content: "\e97f";
  
}
.icon.icon_press:before {
  content: "\e980";
  
}
.icon.icon_profile:before {
  content: "\e981";
  
}
.icon.icon_question:before {
  content: "\e982";
  
}
.icon.icon_rating:before {
  content: "\e983";
  
}
.icon.icon_read:before {
  content: "\e984";
  
}
.icon.icon_reload:before {
  content: "\e985";
  
}
.icon.icon_remove:before {
  content: "\e986";
  
}
.icon.icon_remove_circle:before {
  content: "\e987";
  
}
.icon.icon_return:before {
  content: "\e988";
  
}
.icon.icon_right:before {
  content: "\e989";
  
}
.icon.icon_right_app:before {
  content: "\e98a";
  
}
.icon.icon_rocket:before {
  content: "\e98b";
  
}
.icon.icon_ruler:before {
  content: "\e98c";
  
}
.icon.icon_sad:before {
  content: "\e98d";
  
}
.icon.icon_save:before {
  content: "\e98e";
  
}
.icon.icon_search:before {
  content: "\e98f";
  
}
.icon.icon_send:before {
  content: "\e990";
  
}
.icon.icon_settings:before {
  content: "\e991";
  
}
.icon.icon_share:before {
  content: "\e992";
  
}
.icon.icon_shop:before {
  content: "\e993";
  
}
.icon.icon_smile:before {
  content: "\e994";
  
}
.icon.icon_speek:before {
  content: "\e995";
  
}
.icon.icon_star:before {
  content: "\e996";
  
}
.icon.icon_stats:before {
  content: "\e997";
  
}
.icon.icon_sun:before {
  content: "\e998";
  
}
.icon.icon_tag:before {
  content: "\e999";
  
}
.icon.icon_target:before {
  content: "\e99a";
  
}
.icon.icon_theater:before {
  content: "\e99b";
  
}
.icon.icon_train:before {
  content: "\e99c";
  
}
.icon.icon_translate:before {
  content: "\e99d";
  
}
.icon.icon_trash:before {
  content: "\e99e";
  
}
.icon.icon_truck:before {
  content: "\e99f";
  
}
.icon.icon_tshirt:before {
  content: "\e9a0";
  
}
.icon.icon_ufo:before {
  content: "\e9a1";
  
}
.icon.icon_umbrella:before {
  content: "\e9a2";
  
}
.icon.icon_unlock:before {
  content: "\e9a3";
  
}
.icon.icon_up:before {
  content: "\e9a4";
  
}
.icon.icon_updown:before {
  content: "\e9a5";
  
}
.icon.icon_upload:before {
  content: "\e9a6";
  
}
.icon.icon_view:before {
  content: "\e9a7";
  
}
.icon.icon_voltage:before {
  content: "\e9a8";
  
}
.icon.icon_voucher:before {
  content: "\e9a9";
  
}
.icon.icon_voucher_air:before {
  content: "\e9aa";
  
}
.icon.icon_warning:before {
  content: "\e9ab";
  
}
.icon.icon_wifi:before {
  content: "\e9ac";
  
}
.icon.icon_wishlist:before {
  content: "\e9ad";
  
}
.icon.icon_wishlist_color:before {
  content: "\e9ae";
  color: #ff5758;
}
