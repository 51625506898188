.nav-tabs .nav-link {
    border-bottom: 1px solid ddd;
}

.nav-tabs .nav-link.active {
    border-color: #fff #fff #ec7000;
    color: #ec7000;
}
.nav-link {
    color: #56504C;
    font-weight: 700;
    font-size: 16px;
}

.infoPoints {
    margin-top: 40px;
}

.infoPoints .border {
    border-bottom: 2px solid #DDD;
    margin-left: 20px;
    margin-right: 20px;
}

.infoAddPoints {
    margin-top: 40px;
}

.client {
    display: flex;
    margin-top: -20px;
    margin-left: 130px;
    color: rgb(120, 120, 120);
}

.border-bottom-ddd {
    border-bottom: 1px solid #DDD;
}

.nav-link:hover {
    color: #ec7000;
    border-top: 1px solid transparent!important;
    border-left: 1px solid transparent!important;
    border-right: 1px solid transparent!important;
    border-bottom: 1px solid #ec7000!important;
}

.green-background {
    background-color: #EFFEF8;
}

.gray-background {
    background-color: #F2F4F7;
}

.red-background {
    background-color: #FFDFDF;
}

.btn-list {
    width: 95%;
}

.list-tabs{
    padding: 0;
    list-style: none;
}

.list-tabs button {
    cursor: auto;
}