.App {
  text-align: center;
  max-width: 425px;
  margin: auto;
  overflow-x: hidden;
  position: relative;
}

@import './skin';
@import "./icons.scss"; 
@import "./itau_icons.scss";    

$color-title: #24242d;
$color-text: #6a6a6a;

$brand-1: #ec7000;
$brand-2: #007ab7;
$brand-3: #535353;
$brand-4: #24242d;     
$brand-5: #ffffff;            
$featured: #00efe8;   
$money: #007A47;      
$red-money: #B71C1C;    
$filter: #106EB0;
$no-points: #56504C;
$error: #781B8D;
$text-requests: #231D19;
$old-price: #89837F;
 
$menu-segmentation-bg: #393941;

$ui-1: #a5a5a5;  
$ui-2: #d2d2d2;        
$ui-3: #e8e8e8;
$ui-4: #f5f5f5;          
$ui-5: #f5f5f5;  

$ui-new-1: #707687;

$ui-white: #ffffff;
$ui-black: #000000;

$success: #018557;
$error: #531e6e;
$warning: #fbc979;
$info: #63b2db;

$font-family-title: "Itau Display Custom XBd"; 

$font-family-base: "Itau Display Custom Regular";
$font-family-base-bold: "Itau Display Custom Bold";
$font-family-featured: "Itau Display Custom Regular";
$font-family-featured-black: "Itau Display Custom Bold";

* {
  font-family: $font-family-base, sans-serif;
  letter-spacing: 0.5px;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #000 !important;
}

.gap10 { 
  gap: 10px;
}

.no-opacity {
  opacity: 1;
}

.mt-03 {
  margin-top: 3px;  
}

.mt-05 {
  margin-top: 5px;  
}

.mb-6 {
  margin-bottom: 6px;
}

.d-flex-all-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
}

.d-flex-end-center {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.d-flex-start-center {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right; 
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-32 {
  font-size: 32px;
}

.bold {
  font-weight: 700;
}

.txt-header {
  font-family: $font-family-base , sans-serif;
  font-size: 16px;
}

.txt-decoration {
  text-decoration: underline !important; 
}

.txt-line-through {
    text-decoration: line-through;
}

.txt-title-carrousel {
    font-family: $font-family-base-bold, sans-serif;
    font-size: 24px;
    line-height: 32px;
}

.txt-little-price-carrousel {
    font-family: $font-family-base, sans-serif;
    font-size: 16px;
    line-height: 32px;
}

.txt-big-price-carrousel {
    font-family: $font-family-base, sans-serif;
    font-size: 24px;
    line-height: 32px;
}

.back-to-home {
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 9999;
}

.m-app {
  margin: 0 24px;
}

.icon-slider-size {
  width: 28px;
  height: 28px;
}

.icon-slider-size-minor {
  width: 28px;
  height: 18px;
}

.icon-custom {
    &.icon_plane {
      background-image: url("./img/icons/icon-plane.png");
      background-size: contain; 
      background-repeat: no-repeat; 
      display: block;
    }
    &.icon_points {
        background-image: url("./img/icons/icon-points.png");
        background-repeat: no-repeat;
        display: block;
      }
    &.icon_promo {
      background-image: url("./img/icons/icon-promo.png");
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
    }
    &.icon_bag {
      background-image: url("./img/icons/icon-bag.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 18px;
      height: 18px;
      display: block;
    }
    &.icon_arrow_left {
      background-image: url("./img/icons/icon-arrow-left.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 10px;
      height: 17px;  
      display: block;
    }
    &.icon_arrow_left_gray {
      background-image: url("./img/icons/icon-arrow-left-gray.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 10px;
      height: 17px;  
      display: block;
    }
    &.icon_promo_orange {
      background-image: url("./img/icons/icon-promo-orange.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 29px;
      height: 16px;  
      display: block;
    }
    &.icon_times {
      background-image: url("./img/icons/icon-times.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;  
      display: block;
      margin: 0 0 0 10px;
    }
    &.icon_card {
      background-image: url("./img/icons/icon_card.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 28px;
      height: 21px;  
      display: block;
    }
    &.icon_warning {
      background-image: url("./img/icons/icon_warning.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 28px;
      height: 26px; 
    } 
    &.icon_info {
      background-image: url("./img/icons/icon-info.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;  
      display: block;
    }
    &.icon_open_new_window_blue {
      background-image: url("./img/icons/icon-open-new-window-blue.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;  
      display: block;
    }
    &.icon_phone_blue {
      background-image: url("./img/icons/icon-phone-blue.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;  
      display: block;
    }
    &.icon_points_black {
      background-image: url("./img/icons/icon-points-black.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;  
      display: inline-block;
    }
    &.icon_dolar_black {
      background-image: url("./img/icons/icon-dolar-black.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;  
      display: inline-block;
    }
    &.icon_arrow_right {
      background-image: url("./img/icons/icon-arrow-right.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 7px;
      height: 12px;  
      display: block;
    }
    &.icon_warning_purple {
      background-image: url("./img/icons/icon_warning_purple.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 22px;
      height: 20px;  
      display: block;
    }
  }

  .position-icon-10 {
    position: relative;
    top: 10px;
  }

  .position-icon-5 {
    position: relative;
    top: 5px;
  }

  .title-points {
    font-family: $font-family-base-bold, sans-serif;
    font-size: 15px;
    line-height: 32px;
    text-align: left;
  }

  .border-bottom-ui-1 {
    border-bottom: 2px solid #DDD;
  }

  .color-brand-1 {
    color: $brand-1;
  }

  .color-fff {
    color: $brand-5;
  }
    
  .color-title {
    color: $color-title;
  }

  .color-ui-new-1 {
    color: $ui-new-1;
  }

  .color-money {
    color: $money;
  }

  .color-old-price {
    color: $old-price;
  }

  .color-red-money {
    color: $red-money !important;
  }

  .color-blue {
    color: $filter;
  }

  .color-requests {
    color: $text-requests;
  }

  .bg-color-black {
    background-color: $ui-black;
  }

  .bg-old-price {
    background-color: $old-price;
  }

  .loader {
    border: 2px solid;
    border-color: $brand-1;
    border-top: 2px solid $ui-white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }

  .special-width {
    min-width: 90px !important; 
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .filter-counter {
    width: 20px;
    height: 20px;
    background-color: $filter;
    border-radius: 50%;
    font-size: 14px;
    color: $ui-white;
    text-align: center;
  }

  .filter-col {
    margin: 0 24px;
    cursor: pointer;
}

.position-icon-filter {
    position: relative;
    top: 2px;
    right: 5px;
}

.d-flex-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    gap: 8px;
    overflow-x: scroll;
}

.button-filter {
    border: 1px solid #ddd;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px; 
    color: #56504C;
    text-align: center;
    padding: 7px 3px;
    margin-bottom: 5px;
    cursor: pointer;
}

.button-filter.active {
    border: 1px solid #EC7000;
    background-color: #FAF7F5;
    color: #231D19;
    font-weight: 700;
}

.submit-filters {
    width: 100%;
    background-color: #EC7000;
    border: 2px solid #DD6100;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    line-height: 24px;
    padding: 10px 0;
    border-radius: 8px;
    &:disabled {
      background-color: #D6D0CC;
      color: #89837F;
      border: 2px solid #D6D0CC;
    }
}
  
  .color-black {
    color: $ui-black;
  }

  .color-no-points {
    color: $no-points;
  }

  .clean-filters {
    width: 100%;
    background-color: transparent;
    border: 1px solid #106EB0;
    font-size: 16px;
    font-weight: 700;
    color: #106EB0;
    line-height: 24px;
    padding: 10px 0;
    border-radius: 8px;
  }

  .btn-reload {
    width: 140px;
    background-color: transparent;
    border: 2px solid #106EB0;
    font-size: 14px;
    font-weight: 700;
    color: #106EB0;
    line-height: 24px;
    padding: 3px 0;
    border-radius: 8px;
  }

  .color-featured {
    color: $brand-1;
  }

  .color-error {
    color: $error;
  }

  .txt-decoration-none {
    text-decoration: none;
  }

  .pointer {
    cursor: pointer;
  }

  .error-div {
    margin-top: 200px;
  }
  
  .date-capitalize {
    text-transform: capitalize;
  }

  .white-bg,
  .nav-tabs {
    background-color: $brand-5;
  }

  .box-requests {
    border: 1px solid #EFE9E5;
    border-radius: 12px;    
    padding: 24px;
  }

  .icon-with-rounded-background {
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    &.w-32 {
      width: 32px;
      height: 32px;
    }
    &.w-48 {
      width: 48px;
      height: 48px;
    }
    & i {
      font-size: 21px;
    }
}

.line-request {
  width: 1px;
  height: 20px;
  margin: auto;
}

.ball-request { 
  width: 16px;
  margin: auto;
}

.icon-position-home-card {
  position: relative;
  top: 2px;
  left: 3px;
}

.img-position-transfer {
  position: relative;
  left: -15px;
  width: 42px;
}

.form-travel {
  label {
    padding: 1rem 0 !important;
    font-size: 14px;
    &:focus {
      font-weight: 700;
    }
  }
  .input-points { 
    border-bottom: 2px solid #89837F;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 20px;
    &:focus {
      border-bottom: 2px solid #DD6100 !important;
      border-color: transparent;
      box-shadow: none;
      outline: none;
    }
  }
}

.form-credit {
  label {
    padding: 1rem 0 !important;
    font-size: 14px;
    &:focus {
      font-weight: 700;
    }
  }
  .input-points { 
    border-bottom: 2px solid #89837F;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 16px;
    &:focus {
      border-bottom: 2px solid #DD6100 !important;
      border-color: transparent;
      box-shadow: none;
      outline: none;
    }
  }
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.925rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 5px !important;
} 

.form-check-input:checked {
  background-color: $brand-1 !important;
  border-color: $brand-1 !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-select {
  background-position: 98% 35px !important;
}

.form-floating>.form-select {
  padding-top: 1.925rem !important;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control, .form-floating>.form-select {
  height: calc(3.5rem + 5px) !important; 
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  opacity: 1 !important;
}

.green-points {
  background: #E3F4EF;
  font-weight: 700;
  font-size: 14px;
  color: #007A47;
  text-align: center;
  border-radius: 4px;
  padding: 0 4px; 
}

.img-radius {
  border-radius: 50%;
  border: 2px solid #DDD;
}

.bannerTravels {
  width: 148px; 
  height: 204px;
  background-size: cover; 
  background-repeat: no-repeat; 
  display: block;
  border-radius: 5px; 
}

.alert-warning {
  background-color: #F1AE2F !important;
}

@media screen and (max-width: 624px) {
  .bannerTravels {
    height: 168px;
    border-radius: 5px;
  }
}

.special-radius {
  border-radius: 9px 0 0 0;
}

.itoken-loading {
  background-color: $brand-1;
  position: absolute;
  top: 0;
  width: 100%;
}

.back-black {
  background: #000;
  height: 95vh;
  position: absolute;
  top: 82px;
  width: 100%;
  z-index: 1;
  opacity: 0.7;
}

.warning-points {
  margin: 5px; 
  position: relative;
  top: 2px;
}

.btn-fixed-bottom {
  position: absolute; 
  bottom: 0;
  right: 0;
  left: 0;
}

.height-special {
  height: 94vh;
}

.f-none:focus {
  outline-offset: 0.25em;
}